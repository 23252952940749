const Divider = () => (
  <div
    style={{
      borderBottom: '1px solid black',
      width: '99%',
      margin: 'auto',
      paddingTop: 20,
      marginRight: 20,
    }}
  />
)

export default Divider
